import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as IcoTriangleRight } from '../images/ico-triangle-right.svg';
import { ReactComponent as IcoDocument } from '../images/ico-document.svg';
import { useUserTypeValue, useIsHideNav } from '../app';

const menu = [
    {
        name: 'Home',
        url: '/',
    },
    {
        name: 'PDR Form',
        url: '/pdr-form',
    },
    {
        name: 'PIP Form',
        url: '/pip-form',
    },
];

const PopMenuItem = (props) => {
    return (
        <div className='bg-[#999] bg-opacity-10 py-4 px-5 rounded mb-2 flex items-center justify-between cursor-pointer hover:opacity-70 transition-all ease-linear'>
            {props.children}
            <IcoTriangleRight className='fill-dark-grey ml-1 h-8 w-10' />
        </div>
    );
};

const Nav = () => {
    const [isSideMenuOpen, setIsSideMenuOpen] = React.useState(false);
    const location = useLocation();
    const [userType] = useUserTypeValue();
    const [isHideNav] = useIsHideNav();

    React.useEffect(() => {
        setIsSideMenuOpen(false);
    }, [location]);

    return (
        <>
            <div className='bg-white shadow-nav flex sticky z-30 w-full top-0 px-4 lg:px-12'>
                <div className='flex items-center py-2'>
                    <Link to='/'>
                        <img src={require('../images/logo.png')} alt='' />
                    </Link>
                </div>
                <img
                    className='ml-auto cursor-pointer lg:hidden'
                    onClick={() => setIsSideMenuOpen(!isSideMenuOpen)}
                    src={require('../images/ico-burger-menu.svg').default}
                    alt=''
                />
                <ul
                    className='ml-14 hidden lg:flex header-menu-container'
                    style={isHideNav ? { display: 'none' } : {}}
                >
                    <li className='header-menu-item'>
                        <Link to='/' className='block px-4 py-5'>
                            Home
                        </Link>
                    </li>
                    <li className='header-menu-item'>
                        <Link to='/pdr-form' className='block px-4 py-5'>
                            PDR Form
                        </Link>
                        <ul
                            id='pdr-dropdown'
                            className='header-dropdown cursor-pointer'
                        >
                            <li className='font-light text-slate-800'>
                                <Link to='/pdr-form'>My PDR Form</Link>
                            </li>
                            <li className='font-light text-slate-800'>
                                <Link to='/pdr-form'>All PDR Form</Link>
                            </li>
                            <li className='font-light text-slate-800'>
                                <Link to='/pdr-form'>Team Overview</Link>
                            </li>
                        </ul>
                    </li>
                    <li id='pip-menu-item' className='header-menu-item'>
                        <Link to='/pip-form' className='block px-4 py-5'>
                            PIP Form
                        </Link>
                        <ul
                            id='pip-dropdown'
                            className='header-dropdown cursor-pointer'
                        >
                            <li className='font-light text-slate-800'>
                                <Link to='/pip-form'>My PIP Form</Link>
                            </li>
                            <li className='font-light text-slate-800'>
                                <Link to='/pip-form'>All PIP Form</Link>
                            </li>
                            <li className='font-light text-slate-800'>
                                <Link to='/launch-pip-form'>
                                    Launch PIP Form
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className='header-menu-item'>
                        <a
                            className='block px-4 py-5'
                            name='Dashboard and Report'
                            href='https://www.figma.com/proto/cXZBjFV8awMKkR95f6sVra/For-APJ-Development-UST-0622updated?page-id=1648%3A111258&node-id=1648%3A111279&viewport=-787%2C205%2C0.35&scaling=scale-down&starting-point-node-id=1648%3A111279&hide-ui=1'
                            target='_blank'
                            rel='noreferrer'
                        >
                            Dashboard and Report
                        </a>
                    </li>
                    <li className='header-menu-item'>
                        <a
                            className='block px-4 py-5'
                            name='Dashboard and Report'
                            href='https://www.figma.com/proto/cXZBjFV8awMKkR95f6sVra/For-APJ-Development-UST-0622updated?page-id=823%3A78553&node-id=1665%3A109446&viewport=-234%2C265%2C0.08&scaling=scale-down&starting-point-node-id=1665%3A109446&hide-ui=1'
                            target='_blank'
                            rel='noreferrer'
                        >
                            Calibration
                        </a>
                    </li>
                </ul>
                <ul
                    className='ml-auto py-4 hidden lg:flex nav-right-container'
                    style={isHideNav ? { display: 'none' } : {}}
                >
                    <li className='flex items-center px-6 text-sm text-light-grey'>
                        <span>中</span>
                        <span className='px-1'> | </span>
                        <span className='font-bold text-black'>ENG</span>
                    </li>
                    <li className='flex items-center px-6 border-l relative btn-pdr-ref cursor-pointer'>
                        <IcoDocument />
                        <div className='pop-menu absolute pt-5 top-full right-0 z-20'>
                            <div className='bg-white w-[500px] rounded-xl py-7 px-8 shadow-lg'>
                                <div className='flex justify-between items-center mb-5'>
                                    <div className='text-xl font-bold'>
                                        PDR Reference
                                    </div>
                                    <div className='underline flex items-center'>
                                        View All
                                        <IcoTriangleRight className='fill-dark-grey ml-1 h-5 w-7' />
                                    </div>
                                </div>
                                <PopMenuItem>PDR Guide</PopMenuItem>
                                <PopMenuItem>
                                    Other Reference Materials
                                </PopMenuItem>
                                <PopMenuItem>
                                    Timeline of PDR (2020-21)
                                </PopMenuItem>
                                <PopMenuItem>
                                    My Job Description (JD)
                                </PopMenuItem>
                                <PopMenuItem>Sample Guide</PopMenuItem>
                                <PopMenuItem>Sample Guide 2</PopMenuItem>
                            </div>
                        </div>
                    </li>
                    <li className='flex items-center px-6 border-l'>
                        <div className='rounded-full w-9 h-9 bg-light-grey-2'></div>
                        <div className='ml-3 text-main font-bold'>
                            {userType}
                        </div>
                        <img
                            className='ml-3 mt-[-3px] h-[9px]'
                            src={
                                require('../images/ico-arrow-down.svg').default
                            }
                            alt=''
                        />
                    </li>
                </ul>
            </div>
            <div
                className={`fixed w-full h-full z-20 lg:hidden ${
                    isSideMenuOpen ? '' : 'pointer-events-none'
                }`}
            >
                <div
                    onClick={() => setIsSideMenuOpen(false)}
                    className={`bg-black w-full h-full transition-all ease-linear ${
                        isSideMenuOpen ? 'opacity-40' : 'opacity-0'
                    }`}
                ></div>
                <div
                    className={`bg-white w-5/6 h-full absolute top-0 transition-all ease-in-out ${
                        isSideMenuOpen
                            ? 'opacity-100 right-0'
                            : 'opacity-0 -right-20'
                    }`}
                >
                    <div className='py-5 px-4 flex items-center'>
                        <div className='w-16 h-16 bg-light-grey-2 rounded-full mr-4'></div>
                        <div className='flex flex-col'>
                            <div className='font-bold'>AO_account_demo</div>
                            <div className='underline text-main text-sm'>
                                View Profile &gt;{' '}
                            </div>
                        </div>
                    </div>
                    <ul className='w-full border-t border-t-light-grey-2'>
                        {menu.map((item, i) => {
                            return (
                                <li
                                    className={`border-b border-b-light-grey-2
										${
                                            location.pathname.split('/')[1] ===
                                            item.url.split('/')[1]
                                                ? 'bg-main text-white'
                                                : ''
                                        }`}
                                    key={i}
                                >
                                    <Link
                                        to={item.url}
                                        className='block px-4 py-5'
                                    >
                                        {item.name}
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </>
    );
};

export default Nav;
