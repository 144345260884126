import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import InfoCard from "../components/InfoCard";
import InfoCardItem from "../components/InfoCardItem";
import Field from "../components/Field";
import Collapse from "../components/Collapse";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

import { useUserTypeValue } from "../app";

const anchors = [
  { id: "appraisee", name: "Appraisee Information" },
  { id: "review", name: "Review Information" },
  { id: "performance", name: "Performance Objectives" },
  { id: "development", name: "Development Objectives" },
];

const PipDetailAfter = () => {
  const [userType, setUserType] = useUserTypeValue();
  React.useEffect(() => {
    setUserType("AO");
  }, []);
  const { hash } = useLocation();
  const [activeAnchor, setActiveAnchor] = React.useState(null);

  React.useEffect(() => {
    if (hash) {
      setActiveAnchor(hash.replace("#", ""));
      window.scrollAnchorLock = true;

      setTimeout(() => {
        window.scrollAnchorLock = false;
      }, 1000);
    }
  }, [hash]);

  return (
    <>
      <div className="fixed bottom-7 right-5 z-10">
        <div className="w-14 h-14 flex items-center justify-center rounded-full bg-tertiary mb-2 opacity-60 hover:opacity-100 cursor-pointer">
          <img src={require("../images/ico-print.svg").default} alt="" />
        </div>
        <div className="w-14 h-14 flex items-center justify-center rounded-full bg-secondary mb-2 opacity-60 hover:opacity-100 cursor-pointer">
          <img src={require("../images/ico-paperclip.svg").default} alt="" />
        </div>
      </div>

      <div className="main-container mx-auto mt-10">
        <Breadcrumb
          pages={[
            { name: "AO", url: "/" },
            { name: "PIP Form", url: "/pip-form" },
            { name: "Performance Improvement Plan", url: "/pip-form" },
          ]}
        />

        <section>
          <h1 className="font-bold">
            Performance Improvement Plan for CHAN, Tai Man
          </h1>
          <p className="mt-2 mb-6">
            The Performance Improvement plan (PIP) is a formal performance
            action plan which aims to help Appraisees with demonstrated
            performance deficiencies move towards achieving acceptable work
            standard. PIP is an important tool for Appraising Officers to
            provide Appraisees with guidance and support in the improvement
            process.
            <br />
            <br />
            Please refer to the Framework of{" "}
            <a href="#" className="underline cursor-pointer">
              Performance Improvement & Related Corrective Actions (Chapter 5 of
              PDR Guide)
            </a>{" "}
            for details of setting PIP and other possible corrective actions for
            performance improvement.
          </p>

          <div id="appraisee" className="anchor anchor-detail">
            <InfoCard title="Appraisee Information">
              <InfoCardItem title="Name" value="Lee, XXXXXXX" />
              <InfoCardItem title="Department" value="DBM" />
              <InfoCardItem title="Post" value="Assistant Manager" />
              <InfoCardItem title="Band" value="Band 5" />
              <InfoCardItem
                title="Date start Current Post"
                value="31/03/2021"
              />
              <InfoCardItem title="Date join Department" value="13/12/2019" />
            </InfoCard>
          </div>
        </section>

        <hr className="border-[#aaa] my-6" />

        <div id="performance" className="anchor anchor-detail">
          <Collapse
            title={
              <div className="flex items-center mb-3">
                <h2 className="text-main font-bold">PIP Period</h2>
              </div>
            }
          >
            <div className="flex w-full">
              <div className="w-1/2">
                <b>PIP End date</b>
                <br />
                31/03/2022
              </div>

              <div className="w-1/2">
                <b>PIP End date</b>
                <br />
                30/09/2022
              </div>
            </div>
          </Collapse>
        </div>

        <hr className="border-[#aaa] my-6" />

        <div id="development" className="anchor anchor-detail">
          <Collapse
            title={
              <h2 className="text-main font-bold">Appraisee Performance</h2>
            }
          >
            <p className="mt-3 mb-2 font-medium">
              Performance deficiencies/unacceptable work behaviours identified
              in the last PDR and its consequences/negative impact
            </p>
            <p className="mb-8">
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
              commodo ligula eget dolor. Aenean massa. Cum sociis natoque
              penatibus et magnis dis parturient montes, nascetur ridiculus mus.
              Donec quam felis, ultricies nec, pellentesque eu, pretium quis,
              sem. Nulla consequat massa quis enim. Donec pede justo, fringilla
              vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut,
              imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede
              mollis pretium.
            </p>

            <p className="mb-2 font-medium">
              Expected performance standards to be achieved
            </p>
            <p className="">
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
              commodo ligula eget dolor. Aenean massa. Cum sociis natoque
              penatibus et magnis dis parturient montes, nascetur ridiculus mus.
              Donec quam felis, ultricies nec, pellentesque eu, pretium quis,
              sem. Nulla consequat massa quis enim. Donec pede justo, fringilla
              vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut,
              imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede
              mollis pretium.
            </p>
          </Collapse>
        </div>

        <hr className="border-[#aaa] my-6 mt-10" />

        <div id="performance" className="anchor anchor-detail">
          <Collapse
            title={
              <div className="flex items-center">
                <h2 className="text-main font-bold">Improvement targets</h2>
              </div>
            }
          >
            <p className="mt-2 mb-6">
              Progressing performance improvement targets should be set for
              Appraisee to fully meet performance standards gradually within a
              reasonable timeframe. Timelines for achieving set improvement
              targets in PIP are commonly for 2 to 3 months. The actual duration
              of individual improvement targets should be determined by the
              severity and/or nature of performance issues and Appraisee’s
              capability.
            </p>

            <div class="bg-white p-5 mb-3">
              <div class="flex items-center relative justify-between">
                <div class="font-bold text-xl">Improvement Target 1</div>
              </div>
              <div class="text-[#333] mt-1">
                To be able to perform tasks precisely to serve the purposes of
                the task
              </div>
              <div class="underline font-bold text-main mt-4">Comments</div>
              <div class="rounded text-light-grey border border-light-grey-2 mt-3">
                <div class="w-full border-b flex py-1 px-2">
                  <img src="/static/media/ico-bold.8b1ce69b9f4ae07c8bd2f7a9711d8c6a.svg" />
                  <img src="/static/media/ico-italic.cae52822f103db1822b04260343d5e89.svg" />
                  <img src="/static/media/ico-underline.4a17363cd41b750e564706f27bd580d4.svg" />
                  <img src="/static/media/ico-color.a3b8c6a24d9c9229cde85f4161f546a9.svg" />
                  <img src="/static/media/ico-more.13756d3e7919888f6971516f2880232a.svg" />
                </div>
                <div class="rounded overflow-hidden w-full">
                  <div class="flex-grow text-dark-grey px-3 py-2 pb-4 w-full">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    <span class="font-bold text-secondary">
                      Quis lobortis nisl cursus bibendum sit nulla accumsan
                      sodales ornare
                    </span>
                    . At urna viverra non suspendisse neque, lorem. Pretium
                    condimentum pellentes gravida id etiam sit sed arcu euismod.
                    Rhoncus proin orci duis scelerisque molestie cursus
                    tincidunt aliquam.
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-white p-5 mb-3">
              <div class="flex items-center relative justify-between">
                <div class="font-bold text-xl">Improvement Target 2</div>
              </div>
              <div class="text-[#333] mt-1">
                To be able to perform tasks precisely to serve the purposes of
                the task
              </div>
              <div class="underline font-bold text-main mt-4">Comments</div>
              <div class="rounded text-light-grey border border-light-grey-2 mt-3">
                <div class="w-full border-b flex py-1 px-2">
                  <img src="/static/media/ico-bold.8b1ce69b9f4ae07c8bd2f7a9711d8c6a.svg" />
                  <img src="/static/media/ico-italic.cae52822f103db1822b04260343d5e89.svg" />
                  <img src="/static/media/ico-underline.4a17363cd41b750e564706f27bd580d4.svg" />
                  <img src="/static/media/ico-color.a3b8c6a24d9c9229cde85f4161f546a9.svg" />
                  <img src="/static/media/ico-more.13756d3e7919888f6971516f2880232a.svg" />
                </div>
                <div class="rounded overflow-hidden w-full">
                  <div class="flex-grow text-dark-grey px-3 py-2 pb-4 w-full">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    <span class="font-bold text-secondary">
                      Quis lobortis nisl cursus bibendum sit nulla accumsan
                      sodales ornare
                    </span>
                    . At urna viverra non suspendisse neque, lorem. Pretium
                    condimentum pellentes gravida id etiam sit sed arcu euismod.
                    Rhoncus proin orci duis scelerisque molestie cursus
                    tincidunt aliquam.
                  </div>
                </div>
              </div>
            </div>

            <div class="bg-white p-5 mb-3">
              <div class="flex items-center relative justify-between">
                <div class="font-bold text-xl">Improvement Target 3</div>
              </div>
              <div class="text-[#333] mt-1">
                To be able to perform tasks precisely to serve the purposes of
                the task
              </div>
              <div class="underline font-bold text-main mt-4">Comments</div>
              <div class="rounded text-light-grey border border-light-grey-2 mt-3">
                <div class="w-full border-b flex py-1 px-2">
                  <img src="/static/media/ico-bold.8b1ce69b9f4ae07c8bd2f7a9711d8c6a.svg" />
                  <img src="/static/media/ico-italic.cae52822f103db1822b04260343d5e89.svg" />
                  <img src="/static/media/ico-underline.4a17363cd41b750e564706f27bd580d4.svg" />
                  <img src="/static/media/ico-color.a3b8c6a24d9c9229cde85f4161f546a9.svg" />
                  <img src="/static/media/ico-more.13756d3e7919888f6971516f2880232a.svg" />
                </div>
                <div class="rounded overflow-hidden w-full">
                  <div class="flex-grow text-dark-grey px-3 py-2 pb-4 w-full">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    <span class="font-bold text-secondary">
                      Quis lobortis nisl cursus bibendum sit nulla accumsan
                      sodales ornare
                    </span>
                    . At urna viverra non suspendisse neque, lorem. Pretium
                    condimentum pellentes gravida id etiam sit sed arcu euismod.
                    Rhoncus proin orci duis scelerisque molestie cursus
                    tincidunt aliquam.
                  </div>
                </div>
              </div>
            </div>
          </Collapse>
        </div>

        <hr className="border-[#aaa] my-6" />

        <div id="" className="anchor anchor-detail">
          <Collapse
            title={
              <h2 className="text-main font-bold mb-5">
                Appraisee form setup confirmation
              </h2>
            }
          >
            <div class="bg-white p-4 mb-3">
              <div class="flex items-center relative justify-between">
                <div class="font-bold text-xl mb-3">Confirm this PIP form</div>
              </div>
              <div className="mb-3">
                <input type="checkbox" id="" name="" />
                <label htmlFor="" className="mr-9 text-[#333]">
                  {" "}
                  Appraisee confirmed the Appraisee Performance and Improvement
                  targets in this form
                </label>
              </div>
            </div>
          </Collapse>
        </div>

        <hr className="border-[#aaa] my-6" />

        <div id="" className="anchor anchor-detail">
          <Collapse
            title={
              <h2 className="text-main font-bold mb-5">PIP review meeting</h2>
            }
          >
            <p className="font-bold mb-2">Meeting 1 - 31/03/2022</p>
            <div class="bg-white p-4 mb-6">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
            </div>
            <p className="font-bold mb-2">Meeting 2 - 30/04/2022</p>
            <div class="bg-white p-4 mb-3">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
            </div>
            <Field
              title="Next review meeting date"
              value=""
              placeholder="Input date"
            />
            <div className="rounded text-light-grey border border-light-grey-2 mt-3">
              <div className="w-full border-b flex py-1 px-2">
                <img src={require("../images/ico-bold.svg").default} alt="" />
                <img src={require("../images/ico-italic.svg").default} alt="" />
                <img
                  src={require("../images/ico-underline.svg").default}
                  alt=""
                />
                <img src={require("../images/ico-color.svg").default} alt="" />
                <img src={require("../images/ico-more.svg").default} alt="" />
              </div>
              <div className="rounded overflow-hidden w-full bg-white">
                <textarea
                  className="flex-grow text-dark-grey px-3 py-2 pb-4 w-full"
                  type="text"
                  value="Input comment"
                />
              </div>
            </div>
          </Collapse>
        </div>

        <hr className="border-[#aaa] my-6" />

        <div id="" className="anchor anchor-detail">
          <Collapse
            title={
              <h2 className="text-main font-bold mb-5">Appraisee’s Comments</h2>
            }
          >
            <div class="bg-white p-4 mb-3">
              <div class="underline font-bold text-main">Comments</div>
              <div class="bg-[#F5F5F5] bg-opacity-80 px-5 py-3 mt-3">
                <div class="flex items-center">
                  <div class="text-[#333] font-bold">CHAN, Tai Man</div>
                  <div class="text-[#666] text-sm ml-7">
                    14 Jan 2021 9:00 AM
                  </div>
                </div>
                <div class="text-[#333]">
                  Able to handle the tasks independently.
                </div>
              </div>
            </div>
          </Collapse>
        </div>

        <hr className="border-[#aaa] my-6" />

        <div id="" className="anchor anchor-detail">
          <Collapse
            title={
              <h2 className="text-main font-bold mb-5">
                Recommendation for Action
              </h2>
            }
          >
            <select className="py-2 px-3 w-full">
              <option value="Select Action">Select Action</option>
              <option value="Extend PIP">Extend PIP</option>
              <option value="Extend PIP">Complete</option>
              <option value="Others">Others</option>
            </select>
          </Collapse>
        </div>

        <div className="flex justify-end mt-8">
          <Link className="btn ml-5 btn-xl" to="/pip-detail-after-AO">
            Save and close
          </Link>
        </div>
      </div>
    </>
  );
};

export default PipDetailAfter;
