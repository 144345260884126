import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';

import Nav from './components/Nav';
import ScrollToTop from './components/ScrollToTop';

import Home from './pages/Home';
import PdrForm from './pages/PdrForm';
import LaunchPipForm from './pages/LaunchPipForm';
import PipForm from './pages/PipForm';
import TeamOverview from './pages/TeamOverview';
import PdrDetail from './pages/PdrDetail';
import PipDetail from './pages/PipDetail';
import PipDetailAfterAO from './pages/PipDetail-after-AO';
import PipDetailAfterAP from './pages/PipDetail-after-AP';
import Feedbacks from './pages/Feedbacks';
import PreviewFeedback from './pages/PreviewFeedback';
import FeedbackForm from './pages/FeedbackForm';
import { createGlobalState } from 'react-use';

export const useUserTypeValue = createGlobalState(() => 'AO');
export const useIsHideNav = createGlobalState(() => false);

const App = () => {
    return (
        <BrowserRouter basename='/ustdemo'>
            <ScrollToTop />
            <Nav />
            <div className='pb-20 w-full overflow-hidden'>
                <Routes>
                    <Route exact path='/' element={<Home />} />
                    <Route path='pdr-form' element={<PdrForm />} />
                    <Route path='pip-form' element={<PipForm />} />
                    <Route path='pdr-detail' element={<PdrDetail />} />
                    <Route
                        path='pdr-form/team-overview'
                        element={<TeamOverview />}
                    />
                    <Route path='launch-pip-form' element={<LaunchPipForm />} />
                    <Route path='pip-detail' element={<PipDetail />} />
                    <Route
                        path='pip-detail-after-AO'
                        element={<PipDetailAfterAO />}
                    />
                    <Route
                        path='pip-detail-after-AP'
                        element={<PipDetailAfterAP />}
                    />
                    <Route path='feedbacks' element={<Feedbacks />} />
                    <Route
                        path='preview-feedback'
                        element={<PreviewFeedback />}
                    />
                    <Route path='feedback-form' element={<FeedbackForm />} />
                </Routes>
            </div>
        </BrowserRouter>
    );
};

export default App;
