import React from "react";
import { ReactComponent as IcoTriangleUp } from "../images/ico-triangle-up.svg";

const Collapse = (props) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const hanldleToggle = () => {
    if (!props.isDisableCollapse) {
      setIsOpen((prev) => !prev);
    }
  };

  React.useEffect(() => {
    if (props.isOpen !== undefined) {
      setIsOpen(props.isOpen);
    }
  }, [props.isOpen]);

  React.useEffect(() => {
    if (props.onClose && isOpen === false) {
      props.onClose();
    }
  }, [isOpen]);

  return (
    <div className={`relative ${props.className}`}>
      <div className="collapse-title flex w-full items-center justify-between items-baseline">
        <div className="flex items-center ">{props.title}</div>
        {!props.isDisableCollapse && (
          <IcoTriangleUp
            className={`transition-all ease-in-out cursor-pointer ${
              isOpen ? "fill-main" : "fill-main rotate-180"
            }`}
            onClick={hanldleToggle}
          />
        )}
      </div>
      {isOpen && props.children}
    </div>
  );
};

export default Collapse;
