import { Input } from "postcss";
import React from "react";
import parse from "html-react-parser";

const Field = (props) => {
  return (
    <div className="w-full field-container">
      <div className="font-medium h-7">
        {props.title} {props.isRequired && <span className="text-red">*</span>}
        {props.tooltips ? (
          <img
            className="tooltip-icon ml-1 cursor-pointer"
            src={require("../images/ico-tooltip.svg").default}
            alt=""
          />
        ) : (
          ""
        )}
      </div>
      {props.type === "text" ? (
        <div class="w-full space-x-2 flex">
          <input
            className="py-2 px-3 w-2/3 h-full border border-[#D4D4D4] rounded overflow-hidden"
            type="text"
            defaultValue={props.value}
            placeholder={props.placeholder}
          />
          <input
            className="py-2 px-3 w-1/3 h-full border border-[#D4D4D4] rounded overflow-hidden"
            type="text"
            defaultValue={props.name}
            placeholder={props.placeholder}
          />
        </div>
      ) : (
        <div
          className={`border border-[#D4D4D4] rounded ${props.className} overflow-hidden`}
        >
          {!props.type && (
            <input
              className="py-2 px-3 w-full h-full"
              type="text"
              defaultValue={props.value}
              placeholder={props.placeholder}
            />
          )}

          {props.type === "disabled" && (
            <input
              className="py-2 px-3 w-full h-full text-disabled"
              type="text"
              defaultValue={props.value}
              value={props.placeholder}
              disabled
            />
          )}

          {props.type === "textarea" && (
            <>
              {props.withFormatter && (
                <div className="w-full border-b flex py-1 px-2">
                  <img src={require("../images/ico-bold.svg").default} alt="" />
                  <img
                    src={require("../images/ico-italic.svg").default}
                    alt=""
                  />
                  <img
                    src={require("../images/ico-underline.svg").default}
                    alt=""
                  />
                  <img
                    src={require("../images/ico-color.svg").default}
                    alt=""
                  />
                  <img src={require("../images/ico-more.svg").default} alt="" />
                </div>
              )}
              {props.value ? (
                <div className="py-2 px-3 w-full h-full">
                  {parse(props.value)}
                </div>
              ) : (
                <textarea
                  className="py-2 px-3 w-full h-full"
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  placeholder={props.placeholder}
                ></textarea>
              )}
            </>
          )}

          {props.type === "select" && (
            <select className="py-2 px-3 w-full">
              <option value={props.value}>{props.value}</option>
            </select>
          )}
        </div>
      )}
    </div>
  );
};

export default Field;
