import React from "react";
import parse from "html-react-parser";

const DragItem = (props) => {
  return (
    <div className="bg-white p-5 pl-12 mb-3">
      <div className="flex items-center relative justify-between">
        <div className="absolute -left-7 flex flex-col">
          <div className="w-3 overflow-hidden inline-block mb-1 cursor-pointer group">
            <div className=" h-2 w-2 bg-light-grey rotate-45 transform origin-bottom-left group-hover:bg-secondary"></div>
          </div>
          <div className="w-3 overflow-hidden inline-block cursor-pointer group">
            <div className=" h-2 w-2 bg-light-grey -rotate-45 transform origin-top-left group-hover:bg-secondary"></div>
          </div>
        </div>
        <div className="font-bold text-xl">{props.title}</div>
        <div className="relative group cursor-pointer">
          <img src={require("../images/ico-menu.svg").default} alt="" />
          <div className=" absolute top-0 right-0 hidden group-hover:block pr-3">
            <div className="bg-white shadow-lg min-w-[240px]">
              <div className="px-5 py-2 flex items-center cursor-pointer hover:bg-light-grey-2">
                <img
                  className="mr-2"
                  src={require("../images/ico-audit.svg").default}
                  alt=""
                />
                Audit Trail(Obejective)
              </div>
              <div className="px-5 py-2 flex items-center cursor-pointer hover:bg-light-grey-2">
                <img
                  className="mr-2"
                  src={require("../images/ico-edit.svg").default}
                  alt=""
                />
                Edit
              </div>
              <div className="px-5 py-2 flex items-center cursor-pointer hover:bg-light-grey-2">
                <img
                  className="mr-2"
                  src={require("../images/ico-del.svg").default}
                  alt=""
                />
                Delete
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-[#333] mt-1">
        {props.description
          ? "To be able to perform tasks precisely to serve the purposes of the task"
          : ""}
      </div>

      {props.enableComment === "disabled" ? (
        ""
      ) : (
        <div className="underline font-bold text-main mt-4">Comments</div>
      )}
      {props.comments?.map((comment, i) => {
        return (
          <div className="bg-[#F5F5F5] bg-opacity-80 px-5 py-3 mt-3" key={i}>
            <div className="flex items-center">
              <div className="text-[#333] font-bold">{comment.name}</div>
              <div className="text-[#666] text-sm ml-7">{comment.time}</div>
            </div>
            <div className="text-[#333]">{comment.content}</div>
          </div>
        );
      })}
      <div className="rounded text-light-grey border border-light-grey-2 mt-3">
        <div className="w-full border-b flex py-1 px-2">
          <img src={require("../images/ico-bold.svg").default} alt="" />
          <img src={require("../images/ico-italic.svg").default} alt="" />
          <img src={require("../images/ico-underline.svg").default} alt="" />
          <img src={require("../images/ico-color.svg").default} alt="" />
          <img src={require("../images/ico-more.svg").default} alt="" />
        </div>
        <div className="rounded overflow-hidden w-full">
          {props.value ? (
            <div className="flex-grow text-dark-grey px-3 py-2 pb-4 w-full">
              {parse(props.value)}
            </div>
          ) : (
            <textarea
              className="flex-grow text-dark-grey px-3 py-2 pb-4 w-full"
              type="text"
              placeholder="Comment..."
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DragItem;
