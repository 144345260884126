import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import InfoCard from "../components/InfoCard";
import InfoCardItem from "../components/InfoCardItem";
import DragItem from "../components/DragItem";
import Button from "../components/Button";
import { Link } from "react-router-dom";
import Field from "../components/Field";
import Collapse from "../components/Collapse";
import { useLocation } from "react-router";
import Popup from "../components/Popup";

const anchors = [
  { id: "appraisee", name: "Appraisee Information" },
  { id: "review", name: "Review Information" },
  { id: "performance", name: "Performance Objectives" },
  { id: "development", name: "Development Objectives" },
];

const PipDetail = () => {
  const { hash } = useLocation();
  const [activeAnchor, setActiveAnchor] = React.useState(null);

  const [isShowPopup, setIsShowPopup] = React.useState(false);

  const handleOpenPopup = () => {
    setIsShowPopup(true);
  };

  const handleClosePopup = () => {
    console.log("close");
    setIsShowPopup(false);
  };

  React.useEffect(() => {
    if (hash) {
      setActiveAnchor(hash.replace("#", ""));
      window.scrollAnchorLock = true;

      setTimeout(() => {
        window.scrollAnchorLock = false;
      }, 1000);
    }
  }, [hash]);

  return (
    <>
      <div className="fixed bottom-7 right-5 z-10">
        <div className="w-14 h-14 flex items-center justify-center rounded-full bg-tertiary mb-2 opacity-60 hover:opacity-100 cursor-pointer">
          <img src={require("../images/ico-print.svg").default} alt="" />
        </div>
        <div className="w-14 h-14 flex items-center justify-center rounded-full bg-secondary mb-2 opacity-60 hover:opacity-100 cursor-pointer">
          <img src={require("../images/ico-paperclip.svg").default} alt="" />
        </div>
      </div>

      <div className="main-container mx-auto mt-10">
        <Popup isShow={isShowPopup} onClose={handleClosePopup}>
          <div className="text-main font-bold text-2xl mb-2">
            Submit and Share to Appraisee
          </div>
          Are you sure to launch the PIP form and share this PIP form to
          appraisee?
          <div className="flex justify-between mt-10">
            <div className="btn btn-ghost btn-xl opacity-40">Back</div>
            <div className="flex items-center">
              {/* <div className="btn btn-white mr-4" onClick={handleOpenPopup}>Preview</div> */}
              <Link className="btn btn-xl" to="/pip-detail-after-AP">
                Confirm
              </Link>
            </div>
          </div>
        </Popup>
        <Breadcrumb
          pages={[
            { name: "AO", url: "/" },
            { name: "PIP Form", url: "/pip-form" },
            { name: "Performance Improvement Plan", url: "/pip-form" },
          ]}
        />

        <section>
          <h1 className="font-bold">
            Performance Improvement Plan for CHAN, Tai Man
          </h1>
          <p className="mt-2 mb-6">
            The Performance Improvement plan (PIP) is a formal performance
            action plan which aims to help Appraisees with demonstrated
            performance deficiencies move towards achieving acceptable work
            standard. PIP is an important tool for Appraising Officers to
            provide Appraisees with guidance and support in the improvement
            process.
            <br />
            <br />
            Please refer to the Framework of{" "}
            <a href="#" className="underline cursor-pointer">
              Performance Improvement & Related Corrective Actions (Chapter 5 of
              PDR Guide)
            </a>{" "}
            for details of setting PIP and other possible corrective actions for
            performance improvement.
          </p>

          <div id="appraisee" className="anchor anchor-detail">
            <InfoCard title="Appraisee Information">
              <InfoCardItem title="Name" value="Lee, XXXXXXX" />
              <InfoCardItem title="Department" value="DBM" />
              <InfoCardItem title="Post" value="Assistant Manager" />
              <InfoCardItem title="Band" value="Band 5" />
              <InfoCardItem
                title="Date start Current Post"
                value="31/03/2021"
              />
              <InfoCardItem title="Date join Department" value="13/12/2019" />
            </InfoCard>
          </div>
        </section>

        <hr className="border-[#aaa] my-6" />

        <div id="performance" className="anchor anchor-detail">
          <Collapse
            title={
              <div className="flex items-center mb-3">
                <h2 className="text-main font-bold">PIP Period</h2>
              </div>
            }
          >
            <div className="flex w-full space-x-4">
              <div className="w-1/2">
                <Field
                  title="PIP Start date"
                  tooltips="enable"
                  placeholder="Select date"
                  isRequired
                />
              </div>

              <div className="w-1/2">
                <Field
                  title="PIP End date"
                  placeholder="Select date"
                  isRequired
                />
              </div>
            </div>
          </Collapse>
        </div>

        <hr className="border-[#aaa] my-6" />

        <div id="development" className="anchor anchor-detail">
          <Collapse
            title={
              <h2 className="text-main font-bold">Appraisee Performance</h2>
            }
          >
            <p className="mt-3 mb-3 font-medium">
              Performance deficiencies/unacceptable work behaviours identified
              in the last PDR and its consequences/negative impact
            </p>
            <div className="rounded text-light-grey border border-light-grey-2 mt-3">
              <div className="w-full border-b flex py-1 px-2">
                <img src={require("../images/ico-bold.svg").default} alt="" />
                <img src={require("../images/ico-italic.svg").default} alt="" />
                <img
                  src={require("../images/ico-underline.svg").default}
                  alt=""
                />
                <img src={require("../images/ico-color.svg").default} alt="" />
                <img src={require("../images/ico-more.svg").default} alt="" />
              </div>
              <div className="rounded overflow-hidden w-full bg-white">
                <textarea
                  className="flex-grow text-dark-grey px-3 py-2 pb-4 w-full"
                  type="text"
                  value="Pretium condimentum pellentesque gravida id etiam sit sed arcu euismod. Rhoncus proin orci duis scelerisque molestie cursus tincidunt aliquam. Quis lobortis nisl cursus bibendum sit nulla accumsan sodales ornare. At urna viverra non suspendisse neque, lorem"
                />
              </div>
            </div>

            <p className="mt-3 mb-3 font-medium">
              Expected performance standards to be achieved
            </p>
            <div className="rounded text-light-grey border border-light-grey-2 mt-3">
              <div className="w-full border-b flex py-1 px-2">
                <img src={require("../images/ico-bold.svg").default} alt="" />
                <img src={require("../images/ico-italic.svg").default} alt="" />
                <img
                  src={require("../images/ico-underline.svg").default}
                  alt=""
                />
                <img src={require("../images/ico-color.svg").default} alt="" />
                <img src={require("../images/ico-more.svg").default} alt="" />
              </div>
              <div className="rounded overflow-hidden w-full bg-white">
                <textarea
                  className="flex-grow text-dark-grey px-3 py-2 pb-4 w-full"
                  type="text"
                  value="Rhoncus proin orci duis scelerisque molestie cursus tincidunt aliquam. Quis lobortis nisl cursus bibendum sit nulla accumsan sodales ornare. At urna viverra non suspendisse neque, lorem Pretium condimentum pellentesque gravida id etiam sit sed arcu euismod. "
                />
              </div>
            </div>
          </Collapse>
        </div>

        <hr className="border-[#aaa] my-6 mt-12" />

        <div id="performance" className="anchor anchor-detail">
          <Collapse
            title={
              <div className="flex items-center">
                <h2 className="text-main font-bold">Improvement targets</h2>
              </div>
            }
          >
            <p className="mt-2 mb-6">
              Progressing performance improvement targets should be set for
              Appraisee to fully meet performance standards gradually within a
              reasonable timeframe. Timelines for achieving set improvement
              targets in PIP are commonly for 2 to 3 months. The actual duration
              of individual improvement targets should be determined by the
              severity and/or nature of performance issues and Appraisee’s
              capability.
            </p>

            <DragItem
              title="Improvement Target 1"
              enableComment="disabled"
              value="Lorem ipsum dolor sit amet, consectetur adipiscing elit. <span className='font-bold text-secondary'>Quis lobortis nisl cursus bibendum sit nulla accumsan sodales ornare</span>. At urna viverra non suspendisse neque, lorem. Pretium condimentum pellentes gravida id etiam sit sed arcu euismod. Rhoncus proin orci duis scelerisque molestie cursus tincidunt aliquam."
            />

            <DragItem
              title="Improvement Target 2"
              enableComment="disabled"
              value="Lorem ipsum dolor sit amet, consectetur adipiscing elit. <span className='font-bold text-secondary'>Quis lobortis nisl cursus bibendum sit nulla accumsan sodales ornare</span>. At urna viverra non suspendisse neque, lorem. Pretium condimentum pellentes gravida id etiam sit sed arcu euismod. Rhoncus proin orci duis scelerisque molestie cursus tincidunt aliquam."
            />

            <DragItem
              title="Improvement Target 3"
              enableComment="disabled"
              value="Lorem ipsum dolor sit amet, consectetur adipiscing elit. <span className='font-bold text-secondary'>Quis lobortis nisl cursus bibendum sit nulla accumsan sodales ornare</span>. At urna viverra non suspendisse neque, lorem. Pretium condimentum pellentes gravida id etiam sit sed arcu euismod. Rhoncus proin orci duis scelerisque molestie cursus tincidunt aliquam."
            />

            <Button ico={require("../images/ico-add.svg").default}>
              New Improvement target
            </Button>
          </Collapse>
        </div>

        <div className="flex justify-end mt-8">
          <div className="btn btn-white btn-xl ml-10">Save</div>
          <div className="btn ml-5 btn-xl" onClick={handleOpenPopup}>
            Submit and Share to Appraisee
          </div>
        </div>
      </div>
    </>
  );
};

export default PipDetail;
