import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import InfoCard from "../components/InfoCard";
import InfoCardItem from "../components/InfoCardItem";
import Pagination from "../components/Pagination";
import Button from "../components/Button";
import Field from "../components/Field";
import Collapse from "../components/Collapse";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import Popup from "../components/Popup";

const Feedbacks = () => {
  const { hash } = useLocation();
  const [isAskForFeedbackOpen, setIsAskForFeedbackOpen] = React.useState(
    hash === "#askForFeedback" ? true : false
  );
  const [isShowPopup, setIsShowPopup] = React.useState(false);

  const handleOpenPopup = () => {
    setIsShowPopup(true);
  };

  const handleClosePopup = () => {
    console.log("close");
    setIsShowPopup(false);
  };

  return (
    <div className="main-container mx-auto">
      <Popup isShow={isShowPopup} onClose={handleClosePopup}>
        <div className="text-main font-bold text-2xl">Preview Email</div>
        <div className="bg-white border p-6 mt-6 pb-12">
          Dear Tommy,
          <br />
          <br />
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
          commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus
          et magnis dis parturient montes, nascetur ridiculus mus. Donec quam
          felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla
          consequat massa quis enim. Donec pede justo.
          <br />
          <a
            className="text-secondary"
            href="/ustdemo/feedbacks"
            target="_blank"
          >
            http://demoformlink
          </a>{" "}
          <br />
          <br />
          Regards, <br />
          Emily
        </div>
      </Popup>
      <Breadcrumb
        pages={[
          { name: "PDR Form", url: "/pdr-form" },
          { name: "My Form", url: "/pdr-form" },
          {
            name: "2022-2023 Performance & Development Review Form",
            url: "/pdr-detail",
          },
          { name: "Feedbacks" },
        ]}
      />

      <div className="flex items-center justify-between mb-4">
        <h1 className="font-bold color-[#222]">
          2021-2022 Performance & Development Review Form for LEE Mandy
        </h1>
        <div className="btn btn-xl font-bold">
          <Link to="/pdr-detail">Back to PDR Form</Link>{" "}
        </div>
      </div>

      <InfoCard title="Appraisee Information" isDisableCollapse>
        <InfoCardItem title="Name" value="Lee, XXXXXXX" />
        <InfoCardItem title="Department" value="DBM" />
        <InfoCardItem title="Post" value="Assistant Manager" />
        <InfoCardItem title="Band" value="Band 5" />
        <InfoCardItem title="Date start Current Post" value="31/03/2021" />
        <InfoCardItem title="Date join Department" value="13/12/2019" />
      </InfoCard>

      <div className="bg-white p-5 mb-3">
        <div className="font-bold text-main text-2xl">All Feedbacks</div>
        <Link
          to="#askForFeedback"
          onClick={() => {
            setIsAskForFeedbackOpen(true);
          }}
        >
          <div className="btn mt-5">Ask for Feedback</div>
        </Link>
        <div className="table-grey mt-4">
          <table>
            <thead>
              <tr>
                <th>Title</th>
                <th>Subject</th>
                <th>Send to</th>
                <th>Status</th>
                <th>Sent Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="underline">
                    Feedback of Performance Objectives 1
                  </div>
                </td>
                <td>
                  able to perform tasks precisely to serve the purposes of task
                </td>
                <td>ARO</td>
                <td>Replied</td>
                <td>31/03/2012</td>
                <td style={{ padding: "4px 8px" }}>
                  <Button
                    ico={require("../images/ico-share.svg").default}
                    className="mr-2 btn-sm"
                  >
                    Share to AP
                  </Button>
                  <Button
                    ico={require("../images/ico-download.svg").default}
                    className="mr btn-sm"
                  >
                    Download
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="border-l border-r h-[80px]"></div>
        <Pagination />
      </div>

      <div id="askForFeedback" className="anchor">
        <Collapse
          className="bg-white p-5"
          title={
            <div className="font-bold text-main text-2xl mb-4">
              Ask For Feedback
            </div>
          }
          isOpen={isAskForFeedbackOpen}
          onClose={(status) => setIsAskForFeedbackOpen(false)}
        >
          <div className="w-full flex flex-col items-end multi-items">
            <Field
              title="Recipient Email Address"
              tooltips="enable"
              value="tommy.chan@ust.edu.hk"
              isRequired
            />

            <div className="text-main text-sm mb-4 cursor-pointer font-bold ">
              {/* + Add Recipient */}
            </div>
          </div>
          <div className="flex flex-col items-end mb-4">
            <Field
              value="Performance Objectives 1 - To be able to perform tasks precisely to serve the purposes of the task"
              title="Feedback on Overall Performance / Individual Objective"
              type="select"
            />
          </div>
          <div className="mb-4">
            <Field
              title="Subject"
              value="Default subject from email template"
              isRequired
            />
          </div>
          <div className="mb-4">
            <Field
              title="Message"
              value="Dear Tommy, <br />
              <br />
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
              commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus
              et magnis dis parturient montes, nascetur ridiculus mus. Donec quam
              felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla
              consequat massa quis enim. Donec pede justo. <br />
              <br />
              <a className='text-secondary' href='/ustdemo/feedback-form' target='_blank'>http://demoformlink</a>
              <br />
              <br />
              Regards, <br />
              Emily"
              type="textarea"
              withFormatter
              isRequired
            />
          </div>

          <Link
            to="/ustdemo/preview-feedback"
            target="_blank"
            className="preview-email"
          >
            Preview email
          </Link>

          <div className="mb-4 mt-8">
            <Field title="Remarks" type="textarea" className="h-[80px]" />
          </div>
          <div>
            <input type="checkbox" id="isSendCopy" name="isSendCopy" />
            <label htmlFor="isSendCopy" className="mr-2 mr-9 text-[#333]">
              {" "}
              Send copy to myself
            </label>
          </div>
          <div className="flex justify-end mt-8">
            <div className="btn btn-white btn-xl ml-10">Submit</div>
            <div className="btn ml-5 btn-xl" onClick={handleOpenPopup}>
              Save and close
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default Feedbacks;
