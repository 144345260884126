import React from 'react'
import SearchInput from '../components/SearchInput'

const SearchForm = (props) => {
	return (
		<div className="bg-[#f5f5f5] rounded">
			<div className="bg-main font-bold text-white py-3 px-5 rounded">
				Search 
			</div>
			<div className="pt-3 px-4 pb-2">
				<div className="flex">
					{props.inputs?.map((input, i) => {
						return <SearchInput {...input} key={i} />
					})}
				</div>
				<div className="flex my-3 underline cursor-pointer">
				{props.type === 'advanced-search' && (
				<><img className="mr-3 my-2" src={require('../images/show-triangle.svg').default} alt="" />Show advanced section</>
				)}		
				</div>
				<div className="flex my-3">
					<div className="btn btn-lg mr-3">Search</div>
					<div className="btn btn-lg btn-ghost">Reset</div>
				</div>
			</div>
		</div>
	)
}

export default SearchForm
