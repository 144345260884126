import React from 'react'

const Button = (props) => {
	return (
		<div className={`btn ${props.className}`}>
			{props.ico && <img className="mr-2" src={props.ico} alt="" />}

			{props.children}
		</div>
	)
}

export default Button
