import React, { useState } from 'react'

const Popup = (props) => {
	return (
		<>
			{props.isShow && (
				<div className="fixed w-full h-full top-0 left-0 z-50 flex justify-center items-center">
					<div
						className="bg-black opacity-40 absolute w-full h-full top-0 left-0 cursor-pointer"
						onClick={props.onClose}
					></div>
					<div className="bg-[#FCFCFC] absolute py-10 px-9 w-10/12 max-w-[750px]">
						<img
							className="absolute top-10 right-9 cursor-pointer w-[25px] h-[25px]"
							onClick={props.onClose}
							src={require('../images/ico-close.svg').default}
							alt=""
						/>
						{props.children}
					</div>
				</div>
			)}
		</>
	)
}

export default Popup
