import React from 'react'
import { Link } from 'react-router-dom'

const TabButton = (props) => {
	return (
		<>
			<div
				className={`text-xl rounded-t-md mr-2 ${
					props.isActive
						? 'text-secondary bg-white font-bold'
						: 'text-[#666] bg-[#cecece]'
				}`}
			>
				{props.url ? (
					<Link to={props.url} className="block pt-4 pb-5 px-8">
						{props.children}
					</Link>
				) : (
					<div className="block pt-4 pb-5 px-8">{props.children}</div>
				)}
			</div>
		</>
	)
}

export default TabButton
