import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import InfoCard from "../components/InfoCard";
import InfoCardItem from "../components/InfoCardItem";
import DragItem from "../components/DragItem";
import Button from "../components/Button";
import Field from "../components/Field";
import { Link } from "react-router-dom";
import ProgressBar from "../components/ProgressBar";
import Collapse from "../components/Collapse";
import { useLocation } from "react-router";

const anchors = [
  { id: "appraisee", name: "Appraisee Information" },
  { id: "review", name: "Review Information" },
  { id: "performance", name: "Performance Objectives" },
  { id: "development", name: "Development Objectives" },
];

const PdrDetail = () => {
  const { hash } = useLocation();
  const [activeAnchor, setActiveAnchor] = React.useState(null);

  const handleScroll = () => {
    if (!window.scrollAnchorLock) {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        setActiveAnchor(anchors[anchors.length - 1].id);
        return;
      }

      anchors.map((anchor, i) => {
        const offsetTop = document.getElementById(anchor.id).offsetTop;
        if (offsetTop < window.pageYOffset + 10) {
          setActiveAnchor(anchor.id);
          return;
        }
      });
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  React.useEffect(() => {
    if (hash) {
      setActiveAnchor(hash.replace("#", ""));
      window.scrollAnchorLock = true;

      setTimeout(() => {
        window.scrollAnchorLock = false;
      }, 1000);
    }
  }, [hash]);

  return (
    <>
      <div className="fixed bottom-7 right-5 z-10">
        <div className="w-14 h-14 flex items-center justify-center rounded-full bg-tertiary mb-2 opacity-60 hover:opacity-100 cursor-pointer">
          <img src={require("../images/ico-print.svg").default} alt="" />
        </div>
        <div className="w-14 h-14 flex items-center justify-center rounded-full bg-secondary mb-2 opacity-60 hover:opacity-100 cursor-pointer">
          <img src={require("../images/ico-paperclip.svg").default} alt="" />
        </div>
        <Link
          to="/feedbacks"
          className="w-14 h-14 flex items-center justify-center rounded-full bg-main opacity-60 hover:opacity-100"
        >
          <img src={require("../images/ico-feedback.svg").default} alt="" />
        </Link>
      </div>
      <div className="bg-main fixed w-full top-[68px] z-20">
        <div className="main-container mx-auto flex justify-between py-3.5">
          <div className="text-white font-bold">LEE Mandy</div>
          <div className="flex">
            {anchors.map((anchor, i) => {
              return (
                <Link to={`#${anchor.id}`} key={i}>
                  <span
                    className={`ml-8 text-sm ${
                      anchor.id === activeAnchor
                        ? "text-[#ffd400]"
                        : "text-white"
                    }`}
                  >
                    {anchor.name}
                  </span>
                </Link>
              );
            })}
          </div>
        </div>
      </div>

      <div className="main-container mx-auto mt-20">
        <Breadcrumb
          pages={[
            { name: "AO", url: "/" },
            { name: "PDR Form", url: "/pdr-form" },
            { name: "My Form", url: "/pdr-form" },
            { name: "2022-2023 Performance & Development Review Form" },
          ]}
        />

        <section>
          <h1 className="font-bold">
            2021-2022 Performance & Development Review Form for LEE Mandy
          </h1>
          <p className="mt-2">
            With this Form, Appraising Officer and Appraisee together set and
            document performance and development, objectives timely, track and
            review progress of objectives on a regular basis with exchanges of
            constructive feedbackm and assess performance for the review period.
          </p>

          <div className="mb-7">
            <ProgressBar currentStep={4} theme="light" noPadding />
          </div>

          <div id="appraisee" className="anchor anchor-detail">
            <InfoCard title="Appraisee Information">
              <InfoCardItem title="Name" value="Lee, XXXXXXX" />
              <InfoCardItem title="Department" value="DBM" />
              <InfoCardItem title="Post" value="Assistant Manager" />
              <InfoCardItem title="Band" value="Band 5" />
              <InfoCardItem
                title="Date start Current Post"
                value="31/03/2021"
              />
              <InfoCardItem title="Date join Department" value="13/12/2019" />
            </InfoCard>
          </div>

          <div id="review" className="anchor anchor-detail mb-4">
            <InfoCard title="Appraising Officer Information">
              <InfoCardItem title="Appraising Officer" value="Chan, XXXXXXX" />
              <InfoCardItem
                title="2nd-level Appraising Officer"
                value="Lam, XXXXX"
              />
              <InfoCardItem
                title="Review Period"
                value="01/04/2020 - 31/03/2021"
              />
            </InfoCard>
          </div>
          <div className="btn">Audit Trail</div>
        </section>

        <hr className="border-[#aaa] my-6" />

        <div
          id="performance"
          className="two-title-header  anchor anchor-detail"
        >
          <Collapse
            title={
              <div className="flex">
                <div className="mb-5 w-1/2 yearend-title">
                  <h2 className="text-main font-bold">
                    Performance Objectives
                  </h2>
                </div>
                <div className="mb-5 w-1/2 yearend-title">
                  <h2 className="text-main font-bold flex">
                    Rating
                    <img
                      className="rating-tooltip"
                      src={require("../images/ico-tooltip.svg").default}
                      alt=""
                    />
                  </h2>
                </div>
              </div>
            }
          >
            <div class="bg-white p-5 mb-3 flex">
              <div className="w-1/2 mr-2">
                <div class="flex items-center relative justify-between">
                  <div class="font-bold text-xl">Performance Objectives 1</div>
                  <div class="relative group cursor-pointer">
                    <img
                      src={require("../images/ico-menu.svg").default}
                      alt=""
                    />
                  </div>
                </div>
                <div class="text-[#333] mt-1">
                  To be able to perform tasks precisely to serve the purposes of
                  the task
                </div>
                <div class="underline font-bold text-main mt-4">
                  Self Assessment
                </div>
                <div class="bg-[#F5F5F5] bg-opacity-80 px-5 py-3 mt-3">
                  <div class="flex items-center">
                    <div class="text-[#333] font-bold">AP1_id</div>
                    <div class="text-[#666] text-sm ml-7">
                      14 Jan 2021 9:00 AM
                    </div>
                  </div>
                  <div class="text-[#333]">
                    Able to handle the tasks independently.
                  </div>
                </div>

                <div class="underline font-bold text-main mt-4">Comments</div>
                <div class="bg-[#F5F5F5] bg-opacity-80 px-5 py-3 mt-3">
                  <div class="flex items-center">
                    <div class="text-[#333] font-bold">AP1_id</div>
                    <div class="text-[#666] text-sm ml-7">
                      14 Jan 2021 9:00 AM
                    </div>
                  </div>
                  <div class="text-[#333]">
                    Able to handle the tasks independently.
                  </div>
                </div>

                <Field
                  title=""
                  className="no-label-textarea yearend-height"
                  placeholder="Comment..."
                  type="textarea"
                  withFormatter
                />
              </div>
              <div className="w-1/2 ml-5">
                <div className="rating-button-group">
                  <div className="rating-button">O</div>
                  <div className="rating-button active">EE</div>
                  <div className="rating-button">ME</div>
                  <div className="rating-button">NI</div>
                  <div className="rating-button">U</div>
                </div>
                <div className="rating-des">
                  <b>Consistently Exceeds Expectations</b>Performance
                  consistently meets and frequently exceeds job requirements and
                  performance objectives.Frequently delivers work performance
                  that exceeds competent peers in the same or similar positions.
                  Over the review period, the Appraisee has made significant
                  contributions to the Department/University.
                </div>
              </div>
            </div>

            <div class="bg-white p-5 mb-3 flex">
              <div className="w-1/2 mr-2">
                <div class="flex items-center relative justify-between">
                  <div class="font-bold text-xl">Performance Objectives 2</div>
                  <div class="relative group cursor-pointer">
                    <img
                      src={require("../images/ico-menu.svg").default}
                      alt=""
                    />
                  </div>
                </div>
                <div class="text-[#333] mt-1">
                  To be able to perform tasks precisely to serve the purposes of
                  the task
                </div>
                <div class="underline font-bold text-main mt-4">
                  Self Assessment
                </div>
                <div class="bg-[#F5F5F5] bg-opacity-80 px-5 py-3 mt-3">
                  <div class="flex items-center">
                    <div class="text-[#333] font-bold">AP1_id</div>
                    <div class="text-[#666] text-sm ml-7">
                      14 Jan 2021 9:00 AM
                    </div>
                  </div>
                  <div class="text-[#333]">
                    Able to handle the tasks independently.
                  </div>
                </div>

                <div class="underline font-bold text-main mt-4">Comments</div>
                <div class="bg-[#F5F5F5] bg-opacity-80 px-5 py-3 mt-3">
                  <div class="flex items-center">
                    <div class="text-[#333] font-bold">AP1_id</div>
                    <div class="text-[#666] text-sm ml-7">
                      14 Jan 2021 9:00 AM
                    </div>
                  </div>
                  <div class="text-[#333]">
                    Able to handle the tasks independently.
                  </div>
                </div>

                <Field
                  title=""
                  className="no-label-textarea yearend-height"
                  placeholder="Comment..."
                  type="textarea"
                  withFormatter
                />
              </div>
              <div className="w-1/2 ml-5">
                <div className="rating-button-group">
                  <div className="rating-button">O</div>
                  <div className="rating-button">EE</div>
                  <div className="rating-button">ME</div>
                  <div className="rating-button">NI</div>
                  <div className="rating-button">U</div>
                </div>
                <div className="rating-des"></div>
              </div>
            </div>

            <div class="bg-white p-5 mb-3 flex">
              <div className="w-1/2 mr-2">
                <div class="flex items-center relative justify-between">
                  <div class="font-bold text-xl">Performance Objectives 3</div>
                  <div class="relative group cursor-pointer">
                    <img
                      src={require("../images/ico-menu.svg").default}
                      alt=""
                    />
                  </div>
                </div>
                <div class="text-[#333] mt-1">
                  To be able to perform tasks precisely to serve the purposes of
                  the task
                </div>
                <div class="underline font-bold text-main mt-4">
                  Self Assessment
                </div>
                <div class="bg-[#F5F5F5] bg-opacity-80 px-5 py-3 mt-3">
                  <div class="flex items-center">
                    <div class="text-[#333] font-bold">AP1_id</div>
                    <div class="text-[#666] text-sm ml-7">
                      14 Jan 2021 9:00 AM
                    </div>
                  </div>
                  <div class="text-[#333]">
                    Able to handle the tasks independently.
                  </div>
                </div>

                <div class="underline font-bold text-main mt-4">Comments</div>
                <div class="bg-[#F5F5F5] bg-opacity-80 px-5 py-3 mt-3">
                  <div class="flex items-center">
                    <div class="text-[#333] font-bold">AP1_id</div>
                    <div class="text-[#666] text-sm ml-7">
                      14 Jan 2021 9:00 AM
                    </div>
                  </div>
                  <div class="text-[#333]">
                    Able to handle the tasks independently.
                  </div>
                </div>

                <Field
                  title=""
                  className="no-label-textarea yearend-height"
                  placeholder="Comment..."
                  type="textarea"
                  withFormatter
                />
              </div>
              <div className="w-1/2 ml-5">
                <div className="rating-button-group">
                  <div className="rating-button">O</div>
                  <div className="rating-button">EE</div>
                  <div className="rating-button">ME</div>
                  <div className="rating-button">NI</div>
                  <div className="rating-button">U</div>
                </div>
                <div className="rating-des"></div>
              </div>
            </div>
          </Collapse>
        </div>

        <hr className="border-[#aaa] my-6" />

        <div id="development" className="two-title-header anchor anchor-detail">
          <Collapse
            title={
              <div className="flex">
                <div className="mb-5 w-1/2 yearend-title">
                  <h2 className="text-main font-bold">
                    Development Objectives (Optional)
                  </h2>
                </div>
                <div className="mb-5 w-1/2 yearend-title">
                  <h2 className="text-main font-bold flex">
                    Rating
                    <img
                      className="rating-tooltip"
                      src={require("../images/ico-tooltip.svg").default}
                      alt=""
                    />
                  </h2>
                </div>
              </div>
            }
          >
            <div class="bg-white p-5 mb-3 flex">
              <div className="w-1/2 mr-2">
                <div class="flex items-center relative justify-between">
                  <div class="font-bold text-xl">Development Objectives 1</div>
                  <div class="relative group cursor-pointer">
                    <img
                      src={require("../images/ico-menu.svg").default}
                      alt=""
                    />
                  </div>
                </div>
                <div class="color-[#aaa] text-md dev-cat">
                  Learning and Development Needs CAT1
                </div>
                <div class="text-[#333] mt-1">
                  To be able to perform tasks precisely to serve the purposes of
                  the task
                </div>
                <div class="underline font-bold text-main mt-4">
                  Self Assessment
                </div>
                <div class="bg-[#F5F5F5] bg-opacity-80 px-5 py-3 mt-3">
                  <div class="flex items-center">
                    <div class="text-[#333] font-bold">AP1_id</div>
                    <div class="text-[#666] text-sm ml-7">
                      14 Jan 2021 9:00 AM
                    </div>
                  </div>
                  <div class="text-[#333]">
                    Able to handle the tasks independently.
                  </div>
                </div>

                <div class="underline font-bold text-main mt-4">Comments</div>
                <div class="bg-[#F5F5F5] bg-opacity-80 px-5 py-3 mt-3">
                  <div class="flex items-center">
                    <div class="text-[#333] font-bold">AP1_id</div>
                    <div class="text-[#666] text-sm ml-7">
                      14 Jan 2021 9:00 AM
                    </div>
                  </div>
                  <div class="text-[#333]">
                    Able to handle the tasks independently.
                  </div>
                </div>

                <Field
                  title=""
                  className="no-label-textarea yearend-height"
                  placeholder="Comment..."
                  type="textarea"
                  withFormatter
                />
              </div>
              <div className="w-1/2 ml-5">
                <div className="rating-button-group">
                  <div className="rating-button">O</div>
                  <div className="rating-button">EE</div>
                  <div className="rating-button">ME</div>
                  <div className="rating-button">NI</div>
                  <div className="rating-button">U</div>
                </div>
                <div className="rating-des"></div>
              </div>
            </div>
          </Collapse>
        </div>

        <hr className="border-[#aaa] my-6 mt-12" />

        <div id="" className="two-title-header anchor anchor-detail">
          <Collapse
            title={
              <div className="flex">
                <div className="mb-5 w-1/2 yearend-title">
                  <h2 className="text-main font-bold">Overall Performance</h2>
                </div>
                <div className="mb-5 w-1/2 yearend-title">
                  <h2 className="text-main font-bold">
                    <h2 className="text-main font-bold flex">
                      Rating
                      <img
                        className="rating-tooltip"
                        src={require("../images/ico-tooltip.svg").default}
                        alt=""
                      />
                    </h2>
                  </h2>
                </div>
              </div>
            }
          >
            <div class="bg-white p-5 mb-3 flex">
              <div className="w-1/2 mr-2">
                <div class="flex items-center relative justify-between">
                  <div class="font-bold text-xl">Overall Perforamce</div>
                  <div class="relative group cursor-pointer">
                    <img
                      src={require("../images/ico-menu.svg").default}
                      alt=""
                    />
                  </div>
                </div>
                <div class="text-[#333] mt-1 pr-5">
                  Overall Performance Rating and Comments should be completed by
                  Appraising Officer with endorsement of 2nd-level Appraising
                  Officer. taking into account Appraisee's performance against
                  set performance objectives and other relevant factors.
                  <p className="text-[#f00] mt-2">
                    <b>Important Note: </b>A calibration process will be
                    conducted after Year-end Assessment is completed. Appraising
                    Officer may provide feedback (without the performance
                    ratings) to the Appraisee on past performance and set
                    objectives for the next review year. Performance ratings
                    should ONLY be conveyed to the Appraisee AFTER the
                    calibration process is completed.
                  </p>
                </div>
                <div class="underline font-bold text-main mt-4">
                  Self Assessment
                </div>
                <div class="bg-[#F5F5F5] bg-opacity-80 px-5 py-3 mt-3">
                  <div class="flex items-center">
                    <div class="text-[#333] font-bold">AP1_id</div>
                    <div class="text-[#666] text-sm ml-7">
                      14 Jan 2021 9:00 AM
                    </div>
                  </div>
                  <div class="text-[#333]">
                    Able to handle the tasks independently.
                  </div>
                </div>

                <div class="underline font-bold text-main mt-4">Comments</div>
                <div class="bg-[#F5F5F5] bg-opacity-80 px-5 py-3 mt-3">
                  <div class="flex items-center">
                    <div class="text-[#333] font-bold">AP1_id</div>
                    <div class="text-[#666] text-sm ml-7">
                      14 Jan 2021 9:00 AM
                    </div>
                  </div>
                  <div class="text-[#333]">
                    Able to handle the tasks independently.
                  </div>
                </div>

                <Field
                  title=""
                  className="no-label-textarea yearend-height"
                  placeholder="Comment..."
                  type="textarea"
                  withFormatter
                />
              </div>
              <div className="w-1/2 ml-5">
                <div className="rating-button-group">
                  <div className="rating-button">O</div>
                  <div className="rating-button">EE</div>
                  <div className="rating-button">ME</div>
                  <div className="rating-button">NI</div>
                  <div className="rating-button">U</div>
                </div>
                <div className="rating-des"></div>
              </div>
            </div>
          </Collapse>
        </div>

        <hr className="border-[#aaa] my-6 mt-12" />

        <div
          id="development"
          className="two-title-header anchor anchor-detail mb-5"
        >
          <Collapse
            title={
              <h2 className="text-main font-bold">
                2nd-level Appraising Officer’s Overall Comments (Optional)
              </h2>
            }
          >
            <div className="bg-white w-full p-5 rounded-md mt-6">
              <div className="text-main underline font-bold">Comment</div>
              <div className="bg-[#F5F5F5] bg-opacity-80 px-5 py-3 mt-3">
                <div className="flex items-center">
                  <div className="text-[#333] font-bold">2ndAO</div>
                  <div className="text-[#666] text-sm ml-7">
                    14 Jan 2021 9:00 AM
                  </div>
                </div>
                <div className="text-[#333]">
                  Able to handle the tasks independently.
                </div>
              </div>
            </div>
          </Collapse>
        </div>

        <hr className="border-[#aaa] my-6 mt-12" />

        <div
          id="development"
          className="two-title-header anchor anchor-detail mb-5"
        >
          <Collapse
            title={<h2 className="text-main font-bold">Confirmation</h2>}
          >
            <div className="bg-white w-full p-5 rounded-md mt-6">
              <div class="font-bold text-lg">
                The performance ratings and comments have been discussed and
                confirmed with 2nd-level Appraising Officer.
              </div>
              <div className="checkbox-div content-center flex">
                <input type="checkbox" class="normal-checkbox" />
                <span className="checkbox-label">
                  Assessment confirmed with 2nd-level Appraising Officer.
                </span>
              </div>
            </div>
          </Collapse>
        </div>

        <div className="mt-8">
          <div className="btn btn-white grey btn-xl ml-1">Cancel</div>
          <div className="btn ml-5 float-right btn-xl">Save and close</div>
          <div className="btn btn-white ml-5 float-right btn-xl">Submit</div>
        </div>
      </div>
    </>
  );
};

export default PdrDetail;
