import React from 'react'
import { Link } from 'react-router-dom'
import stepNames from '../config/stepNames'
import { ReactComponent as IcoTriangleRight } from '../images/ico-triangle-right.svg'

const CycleTable = (props) => {
	return (
		<>
			{props.index === 1 && (
				<div className="block lg:hidden w-full h-6 bg-gradient-to-b from-[#C2CDD9]  to-[#E3E7EB]"></div>
			)}
			<div className="bg-white lg:bg-transparent px-4 lg:px-0 py-4 lg:py-0 relative">
				<div className="flex justify-between items-center lg:mt-9 ">
					<h3 className="font-bold text-main lg:text-black">{props.title}</h3>
					<Link
						to={props.viewMore?.url}
						className="text-sm underline flex items-center hover:opacity-60"
					>
						{props.viewMore?.text}
						<IcoTriangleRight className="fill-black" />
					</Link>
				</div>
				<div className="table-blue mt-4 hidden lg:block">
					<table>
						<thead>
							<tr>
								<th>Steps</th>
								<th>Status</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<Link to="/pdr-form" className="underline">
										{props.action}
									</Link>
								</td>
								<td className="text-error">{props.status}</td>
							</tr>
						</tbody>
					</table>
				</div>

				{props.index === 0 && (
					<div className="block lg:hidden">
						<div className="flex my-2">
							<div className="font-bold w-32">Step Action</div>
							<div className="">{stepNames[props.currentStep - 1]}</div>
						</div>
						<div className="flex my-2">
							<div className="font-bold w-32">Status</div>
							<div className="">
								<span className="text-red">{props.status}</span>
							</div>
						</div>
					</div>
				)}

				{props.index === 1 && (
					<div className="block lg:hidden">
						<div className="text-red mb-8">3 require attendtion</div>
						<hr className="w-full absolute top-20 left-0" />
						<div className="flex justify-between">
							<div className="font-bold text-[#333]">
								{stepNames[props.currentStep - 1]}
							</div>
							<span className="text-red font-bold">{props.status}</span>
						</div>
					</div>
				)}
			</div>
		</>
	)
}

export default CycleTable
