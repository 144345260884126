import React from 'react'

const InfoCardItem = (props) => {
	return (
		<div className="w-1/4 mt-4">
			<div className="text-light-grey mb-1">{props.title}</div>
			<div className="text-dark-grey">{props.value}</div>
		</div>
	)
}

export default InfoCardItem
