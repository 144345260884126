import React from 'react'
import { ReactComponent as IcoTriangleLeft } from '../images/ico-triangle-left.svg'
import { ReactComponent as IcoTriangleRight } from '../images/ico-triangle-right.svg'

const PaginationButton = (props) => {
	return (
		<div
			className={`border rounded-sm w-9 h-9 bg-white text-sm flex items-center justify-center cursor-pointer mr-1 ${
				props.isActive
					? 'border-secondary bg-secondary text-white'
					: 'border-[#ddd] text-[#5A595A]'
			}`}
		>
			{props.content}
		</div>
	)
}

const Pagination = () => {
	return (
		<div className="flex items-center bg-[#fafafa] border border-[#ededed] py-2 justify-between px-3">
			<div className="flex items-center">
				<PaginationButton
					content={<IcoTriangleLeft className="fill-main opacity-50" />}
				/>
				<PaginationButton content="1" />
				<div className="text-[#CCCCCB] mr-1 w-9 h-9 text-sm pb-2 flex items-center justify-center">
					...
				</div>
				<PaginationButton isActive content="5" />
				<PaginationButton content="6" />
				<PaginationButton content="7" />
				<div className="text-[#CCCCCB] mr-1 w-9 h-9 text-sm pb-2 flex items-center justify-center">
					...
				</div>
				<PaginationButton content="10" />
				<PaginationButton
					content={<IcoTriangleRight className="fill-main opacity-50" />}
				/>
				<div className="text-sm text-dark-grey px-2">Rows per page</div>
				<div className="border border-[#ddd] text-[#5A595A] bg-white py-1 rounded pr-2">
					<select
						name=""
						id=""
						className="border-none text-dark-grey border-light-grey text-sm px-2 rounded-sm"
					>
						<option value="10">10</option>
						<option value="20" selected="selected">20</option>
						<option value="30">30</option>
						<option value="40">40</option>
						<option value="50">50</option>
					</select>
				</div>
				<div className="text-sm text-dark-grey px-2 ml-4">1 - 20 of 40</div>
			</div>
		</div>
	)
}

export default Pagination
