import React from 'react'
import PdrBox from '../components/PdrBox'

const pdfForms = [
	{
		title: '2021-2022 PDR Review Cycle',
		currentStep: 6,
		tables: [
			{
				title: 'My PDR Form',
				currentStep: 7,
				action: 'Draft your objectives',
				status: 'Overdue',
				viewMore: {
					text: 'View My form',
					url: '',
				},
			},
			{
				title: 'To-DO Items',
				currentStep: 4,
				action: 'Conduct Year-end Review Meeting with your Appraisees',
				status: '10 Overdue',
				viewMore: {
					text: 'View All form',
					url: '/pdr-form',
				},
			},
		],
	},
	{
		title: '2022-2023 PDR Review Cycle',
		currentStep: 2,
		tables: [
			{
				title: 'My PDR Form',
				currentStep: 7,
				action: 'Draft your objectives',
				status: 'Overdue',
				viewMore: {
					text: 'View form',
					url: '',
				},
			},
			{
				title: 'To-DO Items',
				currentStep: 4,
				action: 'Conduct Year-end Review Meeting with your Appraisees',
				status: '10 Overdue',
				viewMore: {
					text: 'View My team',
					url: '/pdr-form',
				},
			},
		],
	},
]

const PdrForm = () => {
	return (
		<div className="mx-auto w-full container">
			{pdfForms.map((pdfForm, i) => {
				return <PdrBox {...pdfForm} key={i} />
			})}
		</div>
	)
}

export default PdrForm
