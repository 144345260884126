import React from "react";
import stepNames from "../config/stepNames";
import ProgressItem from "../components/ProgressItem";

const ProgressBar = (props) => {
  return (
    <div
      className={`lg:rounded-3xl lg:mt-8 relative ${
        !props.theme &&
        "bg-gradient-to-r from-[#003062]  to-[#003974] lg:shadow-blue"
      }
			${!props.noPadding && "py-3 px-4 lg:pt-11 lg:pb-8 lg:px-14"}
			`}
    >
      <div className="absolute top-0 left-0 w-full h-full bg-wave-mobile lg:bg-wave bg-no-repeat bg-cover lg:bg-contain bg-right-center lg:bg-left-center mix-blend-overlay"></div>
      <div className="relative hidden lg:block">
        <div className={`absolute top-1/4 px-24 w-full`}>
          <div className="bg-[#ccc] h-[1px] w-full"></div>
        </div>
        <div className="relative z-10 flex justify-between flex-grow">
          {stepNames.map((name, i) => {
            const stepNumber = i + 1;
            let stepState = "idle";

            if (props.currentStep === stepNumber) {
              stepState = "current";
            }

            if (props.currentStep > stepNumber) {
              stepState = "finished";
            }

            return (
              <ProgressItem
                key={i}
                theme={props.theme}
                name={name}
                stepNumber={stepNumber}
                stepState={stepState}
              />
            );
          })}
        </div>
      </div>
      <div className="block lg:hidden">
        <div className="font-bold text-white">Current step</div>
        <div className="flex items-center my-3">
          <div className="bg-white rounded-full w-8 h-8 text-main font-bold flex justify-center items-center mr-4">
            {props.currentStep}
          </div>
          <div>
            <div className="text-white">{stepNames[props.currentStep - 1]}</div>
            <div className="text-xs text-white opacity-80">
              Due date: XX-XX-XXXX
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
