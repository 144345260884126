import React from 'react'
import { ReactComponent as IcoTick } from '../images/ico-tick.svg'

const ProgressItem = (props) => {
	return (
		<>
			{props.stepState === 'finished' && (
				<div className="flex flex-col items-center flex-grow basis-0">
					<div
						className={` h-10 w-10 rounded-full flex justify-center items-center ${!props.theme && 'bg-[#809CBA]'
							} ${props.theme === 'light' && 'bg-white border border-secondary'}`}
					>
						<IcoTick
							className={`${!props.theme && 'stroke-white'} ${props.theme === 'light' && 'stroke-secondary'
								}`}
						/>
					</div>
					<div
						className={` mt-3 text-sm ${!props.theme && 'text-[#809CBA]'} ${props.theme === 'light' && 'text-[#5A5A5A]'
							}`}
					>
						{props.name}
					</div>
				</div>
			)}

			{props.stepState === 'current' && (
				<div className="flex flex-col items-center flex-grow basis-0">
					<div
						className={`h-10 w-10 rounded-full font-bold flex justify-center items-center ${!props.theme && 'bg-white text-main'
							} ${props.theme === 'light' && 'bg-secondary text-white'}`}
					>
						{props.stepNumber}
					</div>
					<div
						className={`flex items-center mt-3 text-sm text-center max-w-[180px] ${!props.theme && 'text-white'
							} ${props.theme === 'light' && 'text-[#5A5A5A] font-bold'}`}
					>
						{props.name}
					</div>
					<div
						className={`text-xs ${!props.theme && 'text-white'} ${props.theme === 'light' && 'text-secondary'
							}`}
					>
						Due date: XX-XX-XXXX
					</div>
				</div>
			)}

			{props.stepState === 'idle' && (
				<div className="flex flex-col items-center flex-grow basis-0">
					<div
						className={` h-10 w-10 rounded-full flex justify-center items-center ${!props.theme && 'bg-[#457CB4] text-white'
							} ${props.theme === 'light' &&
							'bg-white border border-[#ccc] text-[#ccc]'
							}`}
					>
						{props.stepNumber}
					</div>
					<div
						className={`mt-3 text-sm ${!props.theme && 'text-white'} ${props.theme === 'light' && 'text-[#8C8B8C]'
							}`}
					>
						{props.name}
					</div>
				</div>
			)}
		</>
	)
}

export default ProgressItem
