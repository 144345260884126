import React, { useEffect } from 'react';
import Breadcrumb from '../components/Breadcrumb';
import InfoCard from '../components/InfoCard';
import InfoCardItem from '../components/InfoCardItem';
import Pagination from '../components/Pagination';
import Button from '../components/Button';
import Field from '../components/Field';
import Collapse from '../components/Collapse';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import Popup from '../components/Popup';
import { useIsHideNav } from '../app';

const Feedbacks = () => {
    const { hash } = useLocation();
    const [isAskForFeedbackOpen, setIsAskForFeedbackOpen] = React.useState(
        hash === '#askForFeedback' ? true : false
    );
    const [isShowPopup, setIsShowPopup] = React.useState(false);
    const [isHideNav, setIsHideNav] = useIsHideNav();

    useEffect(() => {
        setIsHideNav(true);

        return () => {
            setIsHideNav(false);
        };
    }, []);

    const handleOpenPopup = () => {
        window.close();
    };

    return (
        <div className='main-container mx-auto preview-email-container'>
            <div className=' pt-8 mb-3'>
                <div className='font-bold text-main text-2xl'>
                    Preview Email
                </div>
                <div className='feedback-preview-box'>
                    Dear Tommy, <br />
                    <br />
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                    Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                    natoque penatibus et magnis dis parturient montes, nascetur
                    ridiculus mus. Donec quam felis, ultricies nec, pellentesque
                    eu, pretium quis, sem. Nulla consequat massa quis enim.
                    Donec pede justo. <br />
                    <br />
                    <a
                        className='text-secondary'
                        href='/feedback-form'
                        target='_blank'
                    >
                        http://demoformlink
                    </a>
                    <br />
                    <br />
                    Regards, <br />
                    Emily
                </div>
                <Link
                    to='#askForFeedback'
                    onClick={() => {
                        setIsAskForFeedbackOpen(true);
                    }}
                ></Link>
            </div>
            <div className='flex justify-end mt-8'>
                <div className='btn ml-5 btn-xl' onClick={handleOpenPopup}>
                    Close
                </div>
            </div>
        </div>
    );
};

export default Feedbacks;
