import React from 'react'

const SearchInput = (props) => {
	const Input = () => {
		switch (props.type) {
			case 'text':
				return <input type="text"
					className="p-2 border border-light-grey-2 mt-1 w-full rounded text-sm" placeholder={props.placeholder} />;
				break;
			default:
				return <select
					name=""
					id=""
					className="p-2 border border-light-grey-2 mt-1 w-full rounded"
				>
					<option disabled selected value="">
						{props.placeholder}
					</option>
					{props.options?.map((option, i) => {
						return (
							<>
								<option value="" key={i}>
									{option}
								</option>
								<option value="" key={i}>
									{option}
								</option>
								<option value="" key={i}>
									{option}
								</option>
								<option value="" key={i}>
									{option}
								</option>
								<option value="" key={i}>
									{option}
								</option>
							</>
						)
					})}
				</select>;
				break;
		}
	}
	return (
		<div className="w-1/4 pr-5">
			<div className="font-medium">{props.title}</div>
			<Input />
		</div>
	)
}

export default SearchInput
