import React from "react";
import Field from "../components/Field";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";

const Feedbacks = () => {
  const { hash } = useLocation();
  const [isAskForFeedbackOpen, setIsAskForFeedbackOpen] = React.useState(
    hash === "#askForFeedback" ? true : false
  );
  const [isShowPopup, setIsShowPopup] = React.useState(false);

  const handleOpenPopup = () => {
    window.close();
  };

  return (
    <div className="main-container mx-auto preview-email-container">
      <div className=" pt-8 mb-3">
        <div className="font-bold text-main text-2xl">Feedback Form</div>
        <div className="feedback-preview-box">
          <b>Performance Objective 1</b>
          <br />
          able to perform tasks precisely to serve the purposes of task
          <br />
          <b className="mt-5 block">Performance Objective 2</b>
          ability to manage multiple responsibilities
          <br />
          <hr className="border-[#aaa] my-6" />
          <Field
            title="Feedback"
            className="yearend-height"
            placeholder="Comment..."
            type="textarea"
            withFormatter
            isRequired
          />
          <div className="mt-5">
            <input type="checkbox" id="isSendCopy" name="isSendCopy" />
            <label htmlFor="isSendCopy" className="mr-2 mr-9 text-[#333]">
              {" "}
              Share to Appraisee
            </label>
          </div>
        </div>
      </div>
      <Link
        to="#askForFeedback"
        onClick={() => {
          setIsAskForFeedbackOpen(true);
        }}
      ></Link>

      <div className="flex justify-end mt-8">
        <div className="btn btn-white btn-xl ml-10">Save</div>
        <div className="btn ml-5 btn-xl" onClick={handleOpenPopup}>
          Submit
        </div>
      </div>
    </div>
  );
};

export default Feedbacks;
