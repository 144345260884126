import React from 'react'
import ProgressBar from '../components/ProgressBar'
import CycleTable from '../components/CycleTable'
import { ReactComponent as IcoTriangleUp } from '../images/ico-triangle-up.svg'

const PdrBox = (props) => {
	const [isOpen, setIsOpen] = React.useState(true)
	const hanldleToggle = () => {
		setIsOpen((prev) => !prev)
	}

	return (
		<div
			className={`rounded-xl lg:p-8 lg:mt-10 transition-all ease-linear mb-5 g:mb-0 ${
				isOpen ? 'lg:bg-white lg:pb-11' : 'lg:bg-light-grey'
			}`}
		>
			<div className="flex items-center cursor-pointer">
				<h2
					className={`font-bold transition-all ease-linear text-base lg:text-2xl px-4 lg:px-0 text-main py-4 lg:py-0  ${
						isOpen ? '' : 'lg:text-white'
					}`}
				>
					{props.title}
				</h2>
				<div className="ml-auto hidden lg:block">
					<IcoTriangleUp
						onClick={hanldleToggle}
						className={`transition-all ease-in-out ${
							isOpen ? 'lg:fill-[#222]' : 'lg:fill-white lg:rotate-180'
						}`}
					/>
				</div>
			</div>
			<div className={` ${isOpen ? 'lg:block' : 'lg:hidden'}`}>
				<ProgressBar currentStep={props.currentStep} />
				{props.tables?.map((table, i) => {
					return <CycleTable {...table} index={i} key={i} />
				})}
			</div>
		</div>
	)
}

export default PdrBox
