const stepNames = [
	'Objective Setting',
	'On-going Feedback and Mid-year Check-in',
	'Appraisee’s self-assessment',
	'Year End Assesment',
	'Calibration',
	'Year-end Review Meeting',
	'Appraisee’s Signature',
]

export default stepNames
