import React from 'react'
import Breadcrumb from '../components/Breadcrumb'
import { ReactComponent as IcoTick } from '../images/ico-tick.svg'
import { ReactComponent as IcoTriangleLeft } from '../images/ico-triangle-left.svg'
import { ReactComponent as IcoTriangleRight } from '../images/ico-triangle-right.svg'
import { ReactComponent as IcoTriangleLeftEnd } from '../images/ico-triangle-left-end.svg'
import { ReactComponent as IcoTriangleRightEnd } from '../images/ico-triangle-right-end.svg'
import SearchForm from '../components/SearchForm'
import TabButton from '../components/TabButton'
import Pagination from '../components/Pagination'

const TeamOverview = () => {
	return (
		<div className="main-container mx-auto">
			<Breadcrumb
				pages={[
					{ name: 'AO', url: '/' },
					{ name: 'PDR Form', url: '/pdr-form' },
					{ name: 'Team Overview' },
				]}
			/>
			<div className="flex">
				<TabButton>My Form</TabButton>
				<TabButton url="/pdr-form">All PDR Forms</TabButton>
				<TabButton isActive>Team Overview</TabButton>
				<TabButton>Audit Trail</TabButton>
			</div>
			<div className="rounded -mt-1 bg-white relative z-10 py-6 px-5">
				<SearchForm
					inputs={[
						{
							title: 'PDR Review Cycle',
							options: ['2022-2023 Performance & Development Review Form'],
						},
						{
							title: 'My Team',
							options: ['Search item'],
						},
					]}
				/>

				<div className="mt-8">
					<div className="text-main text-xl font-bold mb-2">
						2022-2023 Performance & Development Review Form
					</div>
					<div className="table-grey">
						<table>
							<thead>
								<tr>
									<th>My Team</th>
									<th>Objective-setting</th>
									<th>On-going feedback & Mid-year Check-in</th>
									<th>Apprarisee’s Self-assessment</th>
									<th>Year-end Assessment</th>
									<th>Calibration</th>
									<th>Year-end Review Meeting</th>
									<th>Supporting Document</th>
								</tr>
							</thead>
							<tbody>
								{[1, 2, 3, 4, 5, 6].map((record, i) => {
									return (
										<tr key={i}>
											<td>CHAN Tai Man</td>
											<td>
												<IcoTick className="stroke-main w-7 h-4" />
											</td>
											<td>
												<IcoTick className="stroke-main w-7 h-4" />
											</td>
											<td>
												<IcoTick className="stroke-main w-7 h-4" />
											</td>
											<td>
												<div className="btn">Review</div>
												<div className="text-xs text-secondary mt-1">
													Due Thu 15/05/2023
												</div>
											</td>
											<td>
												<div className="text-xs text-secondary">
													Due Fri 30/06/2023
												</div>
											</td>
											<td>
												<div className="text-xs text-secondary">
													Due Fri 15/05/2023
												</div>
											</td>
											<td>9</td>
										</tr>
									)
								})}
							</tbody>
						</table>
					</div>
					<Pagination />
				</div>
			</div>
		</div>
	)
}

export default TeamOverview
