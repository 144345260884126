import { useEffect } from 'react'
import { useLocation } from 'react-router'

const ScrollToTop = (props) => {
	const { pathname, hash, key } = useLocation()

	useEffect(() => {
		// if not a hash link, scroll to top
		if (hash === '') {
			window.scrollTo(0, 0)
		}
		// else scroll to id
		else {
			setTimeout(() => {
				const id = hash.replace('#', '')
				const element = document.getElementById(id)

				window.scrollTo({
					top: element.offsetTop,
					left: 0,
					behavior: 'smooth',
				})
			}, 0)
		}
	}, [pathname, hash, key])

	return <>{props.children}</>
}

export default ScrollToTop
