import React from 'react'
import { Link } from 'react-router-dom'

const Breadcrumb = (props) => {
	return (
		<div className="mt-6 mb-3 text-main text-sm">
			{props.pages?.map((page, i) => {
				return (
					<React.Fragment key={i}>
						{i > 0 && ' > '}
						{page.url ? <Link to={page.url}>{page.name}</Link> : page.name}
					</React.Fragment>
				)
			})}
		</div>
	)
}

export default Breadcrumb
