import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import { ReactComponent as IcoTick } from "../images/ico-tick.svg";
import SearchForm from "../components/SearchForm";
import TabButton from "../components/TabButton";
import { Link } from "react-router-dom";
import Pagination from "../components/Pagination";

import { useUserTypeValue } from "../app";

const TeamOverview = () => {
  return (
    <div className="main-container mx-auto w-full">
      <Breadcrumb pages={[{ name: "AO", url: "/" }, { name: "PDR Form" }]} />
      {/* <div className="flex">
				<TabButton>My Form</TabButton>
				<TabButton isActive>All PDR Forms</TabButton>
				<TabButton url="/pdr-form/team-overview">Team Overview</TabButton>
				<TabButton>Audit Trail</TabButton>
			</div> */}
      <div className="rounded -mt-1 bg-white relative z-10 py-6 px-5">
        <div className="text-main text-2xl font-bold mb-2">All PDR Forms</div>

        <SearchForm
          type="advanced-search"
          inputs={[
            {
              title: "Review Cycle",
              placeholder: "Select",
              options: ["Search item"],
            },
            {
              title: "Step",
              placeholder: "Select step",
              options: ["Search item"],
            },
            {
              title: "ITSC Account",
              placeholder: "Text Field",
              type: "text",
            },
            {
              title: "Appraisee Name",
              placeholder: "Text Field",
              type: "text",
            },
          ]}
        />

        <div className="mt-8">
          <div className="text-main text-xl font-bold mb-2">Search Results</div>
          <div className="flex my-3 underline cursor-pointer">
            <img
              className="mr-3"
              src={require("../images/show-triangle.svg").default}
              alt=""
            />
            Show display option
          </div>
          <div className="table-grey">
            <table>
              <thead>
                <tr>
                  <th>
                    Review Cycle{" "}
                    <img
                      className="float-right mt-0.5 cursor-pointer"
                      src={require("../images/item-ordering.svg").default}
                      alt=""
                    />
                  </th>
                  <th>
                    Step{" "}
                    <img
                      className="float-right mt-0.5 cursor-pointer"
                      src={require("../images/item-ordering.svg").default}
                      alt=""
                    />
                  </th>
                  <th>
                    Action{" "}
                    <img
                      className="float-right mt-0.5 cursor-pointer"
                      src={require("../images/item-ordering.svg").default}
                      alt=""
                    />
                  </th>
                  <th>
                    Department Code{" "}
                    <img
                      className="float-right mt-0.5 cursor-pointer"
                      src={require("../images/item-ordering.svg").default}
                      alt=""
                    />
                  </th>
                  <th>
                    Appraisee Name{" "}
                    <img
                      className="float-right mt-0.5 cursor-pointer"
                      src={require("../images/item-ordering.svg").default}
                      alt=""
                    />
                  </th>
                  <th>
                    Leave{" "}
                    <img
                      className="float-right mt-0.5 cursor-pointer"
                      src={require("../images/item-ordering.svg").default}
                      alt=""
                    />
                  </th>
                  <th>
                    Job Title{" "}
                    <img
                      className="float-right mt-0.5 cursor-pointer"
                      src={require("../images/item-ordering.svg").default}
                      alt=""
                    />
                  </th>
                  <th>
                    Band{" "}
                    <img
                      className="float-right mt-0.5 cursor-pointer"
                      src={require("../images/item-ordering.svg").default}
                      alt=""
                    />
                  </th>
                  <th>
                    Appraisee Status{" "}
                    <img
                      className="float-right mt-0.5 cursor-pointer"
                      src={require("../images/item-ordering.svg").default}
                      alt=""
                    />
                  </th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {[
                  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
                  19, 20,
                ].map((record, i) => {
                  return (
                    <tr key={i}>
                      <td>2022-2023 Performance & Development Review Form</td>
                      <td>Objective-setting</td>
                      <td>Set & confirm objectives for your Appraisees</td>
                      <td>ARO</td>
                      <td>CHAN, Tai Man</td>
                      <td>0</td>
                      <td>Assistant Officer(Registry)</td>
                      <td>Band 1</td>
                      <td>Active</td>
                      <td>
                        <div className="btn btn-xs mr-1.5">
                          <img
                            className="mr-1"
                            src={require("../images/ico-doc.svg").default}
                            alt=""
                          />
                          <Link to="/pdr-detail">View</Link>
                        </div>
                        <div className="btn btn-xs">
                          <img
                            className="mr-1"
                            src={
                              require("../images/ico-audit-white.svg").default
                            }
                            alt=""
                          />
                          Audit Trail
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <Pagination />
        </div>
      </div>
    </div>
  );
};

export default TeamOverview;
