import React from 'react'
import Collapse from '../components/Collapse'

const InfoCard = (props) => {
	return (
		<Collapse
			className="bg-white py-5 px-5 mb-3"
			title={<div className="text-main font-bold text-2xl">{props.title}</div>}
			isDisableCollapse={props.isDisableCollapse}
		>
			<div className="flex flex-wrap">{props.children}</div>
		</Collapse>
	)
}

export default InfoCard
