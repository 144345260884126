import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import { ReactComponent as IcoTick } from "../images/ico-tick.svg";
import SearchForm from "../components/SearchForm";
import TabButton from "../components/TabButton";
import { Link } from "react-router-dom";
import Pagination from "../components/Pagination";
const LaunchPipForm = () => {
  return (
    <div className="main-container mx-auto w-full">
      <Breadcrumb pages={[{ name: "Launch PIP Form", url: "/" }]} />
      {/* <div className="flex">
				<TabButton>My Form</TabButton>
				<TabButton isActive>All PDR Forms</TabButton>
				<TabButton url="/pdr-form/team-overview">Team Overview</TabButton>
				<TabButton>Audit Trail</TabButton>
			</div> */}
      <div className="rounded -mt-1 bg-white relative z-10 py-6 px-5">
        <div className="text-main text-2xl font-bold mb-2">Launch PIP Form</div>

        <SearchForm
          inputs={[
            {
              title: "Apprsisee Name",
              placeholder: "Text Field",
              type: "text",
            },
            {
              title: "Department",
              placeholder: "All",
              options: ["Search item"],
            },
            {
              title: "Team",
              placeholder: "All",
              options: ["Search item"],
            },
            {
              title: "Band",
              placeholder: "All",
              options: ["Search item"],
            },
            {
              title: "Job Title",
              placeholder: "All",
              options: ["Search item"],
            },
          ]}
        />

        <div className="mt-8">
          <div className="text-main text-xl font-bold mb-2">Search Results</div>
          <div className="table-grey">
            <table>
              <thead>
                <tr>
                  <th>
                    Appraisee’s Name{" "}
                    <img
                      className="float-right mt-0.5 cursor-pointer"
                      src={require("../images/item-ordering.svg").default}
                      alt=""
                    />
                  </th>
                  <th>
                    Department{" "}
                    <img
                      className="float-right mt-0.5 cursor-pointer"
                      src={require("../images/item-ordering.svg").default}
                      alt=""
                    />
                  </th>
                  <th>
                    Team{" "}
                    <img
                      className="float-right mt-0.5 cursor-pointer"
                      src={require("../images/item-ordering.svg").default}
                      alt=""
                    />
                  </th>
                  <th>
                    Band{" "}
                    <img
                      className="float-right mt-0.5 cursor-pointer"
                      src={require("../images/item-ordering.svg").default}
                      alt=""
                    />
                  </th>
                  <th>
                    Job Title{" "}
                    <img
                      className="float-right mt-0.5 cursor-pointer"
                      src={require("../images/item-ordering.svg").default}
                      alt=""
                    />
                  </th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {[
                  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
                  19, 20,
                ].map((record, i) => {
                  return (
                    <tr key={i}>
                      <td>CHAN, Tai Man</td>
                      <td>ARO</td>
                      <td>Team A</td>
                      <td>Band 1</td>
                      <td>Assistant Officer</td>
                      <td>
                        <div className="btn btn-xs">
                          <img className="" alt="" />
                          <Link to="/pip-detail">Launch PIP Form</Link>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <Pagination />
        </div>
      </div>
    </div>
  );
};

export default LaunchPipForm;
